<template>
    <footer class="footer lancamento-footer">
        <div class="container">

            <hr class="topline">

            <div class="row">
                <div class="col-md-5 col-sm-12">
                    <img width="200" height="36" class="footer-logo" alt="Apolar Imóveis" title="Apolar Imóveis" src="@/assets/img/logo-footer.webp">
                    <div class="footer-resume">
                        <p>Os melhores imóveis em Curitiba e Região Metropolitana  estão na Apolar Imóveis, imobiliária em Curitiba com mais de 50 anos de atuação no mercado. Na Apolar você tem toda a segurança para alugar imóveis, vender ou comprar imóveis.  Com mais de 10.000 imóveis disponíveis e uma rede integrada com mais de 60 lojas, com imóveis em Curitiba e Região Metropolitana.</p>

                        <p>Imóveis residenciais e comerciais ou para comprar e alugar na temporada?  Pensou Imóveis, Pense Apolar.</p>
                    </div>
                </div>

                <div class="col-md-7 col-sm-12 footer-menus">
                    <div class="row">
                        <div class="col-md-4 col-sm-12">
                            <div class="footer-title">VERTICAL</div>
                            <ul class="footer-menus-columns">
                                <li v-for="menu,id in menus.vertical" :key="id">
                                    <a v-bind:key="id" :href="menu.link">{{menu.label}}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-4 col-sm-12">
                            <div class="footer-title">INSTITUCIONAL</div>
                            <ul class="footer-menus-columns">
                                <li v-for="menu,id in menus.institucional" :key="id">
                                    <a v-bind:key="id" :href="menu.link">{{menu.label}}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-4 col-sm-12">
                            <div class="footer-title">CONTATO</div>
                            <ul class="footer-menus-columns">
                                <li v-for="menu,id in menus.contato" :key="id">
                                    <a v-bind:key="id" :href="menu.link">{{menu.label}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-md-6">
                    <div class="copyright">
                        &copy; {{year}}
                        Copyright – Apolar Imóveis | Todos os direitos reservados
                    </div>
                </div>
                <div class="col-md-6">
                    <ul class="nav nav-footer justify-content-end">
                        <li class="nav-item">
                            <a target="_blank" href="https://pt-br.facebook.com/ApolarImoveis%20">
                                <i class="fa fa-facebook"></i>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a target="_blank" href="https://twitter.com/apolar">
                                <i class="fa fa-twitter"></i>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a target="_blank" href="https://www.youtube.com/user/apolarweb">
                                <i class="fa fa-youtube-play"></i>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a target="_blank" href="https://pt.linkedin.com/company/apolar-imoveis">
                                <i class="fa fa-linkedin-square"></i>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a target="_blank" href="https://www.instagram.com/apolarimoveis/?hl=pt-br">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
  name: 'app-footer',
  data() {
    return {
      year: new Date().getFullYear(),
      menus: {
          vertical: [
              {"label":"Vendas","link":"https://www.apolar.com.br/comprar"},
              {"label":"Locação","link":"https://www.apolar.com.br/alugar"},
              {"label":"Apolar Procura","link":"https://procura.apolar.com.br/"},
              {"label":"Temporada","link":"https://www.apolar.com.br/temporada"},
              {"label":"Triple A","link":"https://www.apolar.com.br/triplea"},
              {"label":"Apolar Short Stay","link":"https://shortstay.apolar.com.br/"},
              {"label":"Mapa Online","link":"https://whos.amung.us/stats/maps/2h5ao7ph70/"},
          ],
          institucional: [
              {"label":"Sobre a Apolar","link":"https://www.apolar.com.br/institucional/"},
              {"label":"Central de Ajuda","link":"https://www.apolar.com.br/faq/"},
              {"label":"Nossas Lojas","link":"https://www.apolar.com.br/franquias/"},
              {"label":"Blog","link":"https://www.apolar.com.br/blog"},
              {"label":"Consórcio","link":"https://www.apolar.com.br/consorcio/"},
              {"label":"Area Restrita","link":"https://www.apolar.com.br/franquias-analise-de-dados"},
              {"label":"Política de cookies","link":"https://www.apolar.com.br/politica-de-cookies"},
          ],
          contato: [
              {"label":"Fale Conosco","link":"https://www.apolar.com.br/fale-conosco/"},
              {"label":"Trabalhe Conosco","link":"https://www.apolar.com.br/trabalhe-conosco/"},
              {"label":"Seja um Franqueado","link":"https://apolarfranqueadora.com.br/"},
              {"label":"Franquias","link":"https://www.apolar.com.br/franquias/"},
          ]
      }
    }
  }
};
</script>
<style>
</style>
