<template>
    <div class="lancamento-banner-home-action">
        <div class="container">
            <div class="main-box">
                <h3>Nossos empreendimentos são ideais para aqueles que querem um lugarzinho novo para chamar de seu</h3>
                <div class="description">
                    Veja novos empreendimentos, agende visitas e seja o primeiro proprietário. Tudo isso através da Apolar lançamentos.
                </div>
                <a href="#">
                    Ver lançamentos para venda
                </a>
                <img src="@/assets/img/lancamentos/hands_and_keys.webp" width="1" height="1" alt="Mãos e Chaves">
            </div>
            <div class="small-box">

                <VueSlickCarousel v-bind="slideSettings">
                    <template v-for="item,key in slides" >
                        <div v-bind:key="`item_${key}`">
                            <div class="slide-item">
                                <img :src="item.img" :alt="item.title" width="1" height="1" />
                                <div class="box-info">
                                    <div class="title">{{item.title}}</div>
                                    <div class="info">{{item.description}}</div>
                                    <a v-if="item.link" :href="item.link.url">{{item.link.label}}</a>
                                </div>
                            </div>
                        </div>
                    </template>
                </VueSlickCarousel>

            </div>
        </div>
    </div>
</template>

<script>
import "@/assets/scss/custom/lancamentos/components/banner-home-action.scss"

import Vue from "vue";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: {
    VueSlickCarousel
  },
  props: {
  },
  data() {
    return {
        slides: [
            {
                "img": require("@/assets/img/lancamentos/slider-home-1.webp"),
                "title": "Comece a preparar a mudança",
                "description": "Com a Apolar Imóveis o seu novo imóvel está garantido.",
                "link": {
                    "label": "Ver imóveis a venda",
                    "url": "#"
                }
            },
            {
                "img": require("@/assets/img/lancamentos/slider-home-1.webp"),
                "title": "Comece a preparar a mudança",
                "description": "Com a Apolar Imóveis o seu novo imóvel está garantido.",
                "link": {
                    "label": "Ver imóveis a venda",
                    "url": "#"
                }
            },
            {
                "img": require("@/assets/img/lancamentos/slider-home-1.webp"),
                "title": "Comece a preparar a mudança",
                "description": "Com a Apolar Imóveis o seu novo imóvel está garantido.",
                "link": {
                    "label": "Ver imóveis a venda",
                    "url": "#"
                }
            }
        ]
    }
  },
  computed: {

    slideSettings() {
      let settings = {
        "dots": true,
        "arrows": true,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1
      }

      if (this.isMobile) {
        settings['slidesToShow'] = 1
        settings['dots'] = true
      }

      return settings
    },

  },
  methods: {

  },

  
  mounted() {

  },

  watch:{
  }

};
</script>