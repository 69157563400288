<template>
  <div class="page-property-details-lancamentos">

    <div v-if="isMobile" class="contact-actions-float">
      <div class="talk-with-section">
          <div v-if="immobile.lojacelular != ''" v-on:click="openWhatsapp" class="talk-with">
              <div class="immobile-icons whatsapp"></div>
              <span>{{__('Fale pelo Whatsapp')}}</span>
              <div class="clear"></div>
          </div>

          <div v-if="immobile.lojatelefone != ''" v-on:click="openFone" class="talk-with">
              <div class="immobile-icons fone"></div>
              <span v-if="showFone == ''" >{{__('Fale pelo Telefone')}}</span>
              <span v-else >{{showFone}}</span>
              <div class="clear"></div>
          </div>
      </div>
    </div>

    <div v-if="!isMobile"  class="button-actions button-actions-header">
      <div class="container">
       
        <ul class="media-buttons">
          <li :class="current_vertical_menu == 'gallery' ? 'active':''"  @click="runVerticalMenu('gallery', 0)">
            <img src="@/assets/img/lancamentos/details/detail-picture.webp" class="immobile-description-details" width="1" height="1" alt="Galeria"/>
            Galeria
          </li>

          <li :class="current_vertical_menu == 'map' ? 'active':''"  @click="runVerticalMenu('map', 0)">
            <img src="@/assets/img/lancamentos/details/detail-map.webp" class="immobile-description-details" width="1" height="1" alt="Proximidades"/>
            Mapa
          </li>

          <li  v-if="immobile.video" :class="current_vertical_menu == 'video' ? 'active':''"  @click="runVerticalMenu('video', 0)">
            <img src="@/assets/img/lancamentos/details/detail-video.webp" class="immobile-description-details" width="1" height="1" alt="Video"/>
            Video
          </li>
        </ul>

        
      </div>
    </div>
  
    <PropertyDetailsHeader ref="property-header" :immobile="immobile"></PropertyDetailsHeader>

    
    <div v-if="isMobile"  class="button-actions button-actions-header  button-actions-header-mobile">
      <div class="container">

        <ul class="media-buttons">
          <li :class="current_vertical_menu == 'gallery' ? 'active':''"  @click="runVerticalMenu('gallery', 0)">
            <img src="@/assets/img/lancamentos/details/detail-picture.webp" class="immobile-description-details" width="1" height="1" alt="Galeria"/>
            Galeria
          </li>

          <li :class="current_vertical_menu == 'map' ? 'active':''"  @click="runVerticalMenu('map', 0)">
            <img src="@/assets/img/lancamentos/details/detail-map.webp" class="immobile-description-details" width="1" height="1" alt="Proximidades"/>
            Mapa
          </li>

          <li  v-if="immobile.video" :class="current_vertical_menu == 'video' ? 'active':''"  @click="runVerticalMenu('video', 0)">
            <img src="@/assets/img/lancamentos/details/detail-video.webp" class="immobile-description-details" width="1" height="1" alt="Video"/>
            Video
          </li>
        </ul>

        
      </div>
    </div>

    <section class="property-details">
      <div class="container">    

        <div class="row">

          <div v-if="!isMobile" class="col-md-4 p-0">
            <div class="side-form">
              <PropertyDetailsSidebar :immobile="immobile"></PropertyDetailsSidebar>

              <div class="vertical-info-menu">
                <ul>
                  <li :class="current_vertical_menu == 'gallery' ? 'active':''"  @click="runVerticalMenu('gallery')">
                    <img src="@/assets/img/lancamentos/details/detail-picture.webp" class="immobile-description-details" width="1" height="1" alt="Galeria"/>
                    Galeria
                  </li>
                  <li :class="current_vertical_menu == 'description' ? 'active':''" @click="runVerticalMenu('description')">
                    <img src="@/assets/img/details/details-building.webp" class="immobile-description-details" width="1" height="1" alt="Imóvel"/>
                    Descrição do imóvel
                  </li>
                  <li :class="current_vertical_menu == 'project' ? 'active':''"  @click="runVerticalMenu('project')">
                    <img src="@/assets/img/lancamentos/details/details-floor.webp" class="immobile-description-details" width="1" height="1" alt="Plantas"/>
                    Plantas
                  </li>
                  <li :class="current_vertical_menu == 'map' ? 'active':''"  @click="runVerticalMenu('map')">
                    <img src="@/assets/img/lancamentos/details/detail-map.webp" class="immobile-description-details" width="1" height="1" alt="Proximidades"/>
                    Mapa
                  </li>
                </ul>
              </div>

            </div>
          </div>
          
          <div class="col-md-8 property-details-main-content">
            
            <div class="row">
              <div class="col-md-12">
                <div class="business-title">
                  <div class="title-content">
                     <img v-if="!isMobile" src="@/assets/img/lancamentos/details/details-building-blue.webp" class="immobile-description-details" width="1" height="1" alt="Imóvel"/>
                    {{business_title}}

                    <button v-if="isMobile" @click="shareThis" class="share">
                          <img width="1" height="1" src="@/assets/img/details/details-sharer.webp" alt="Compartilhar imóvel">
                      </button>
                  </div>
                  <!-- <div class="property-ref">ref. {{immobile.referencia}}</div> -->
                </div>
                <h1 class="property-title">
                  {{property_title_h1}}
                  <div v-if="isMobile" class="property-ref">Ref. {{immobile.referencia}}</div>
                </h1>
                <a :href="property_map_url" target="_blank" class="property-address">
                  {{immobile.endereco}}, {{immobile.numero}}, {{immobile.bairro}} - {{immobile.cidade}}
                </a>

                <div class="clear"></div>

                <diiv v-if="!isMobile" class="immobile-description desktop-description">
                  <div class="large-descripto">
                    <div v-html="immobile.descricao"></div>
                  </div>
                </diiv>


                <ul  v-if="isMobile" class="property-features-blue-box">
                  <li v-for="feature in immobile.features">
                    {{feature}}
                  </li>
                </ul>

                <ul class="property-highlights">

                  <template v-for="highlight, idx in property_highlights">
                    <li class="highlight" :key="idx">
                      <img width="1" height="1" :class="highlight.icon" :src="highlight.new_icon" :alt="highlight.value">
                      <span class="highlight-value">
                        {{highlight.value}}
                        <span v-if="highlight.icon == 'room' && immobile.suites" class="small">
                          (
                            {{immobile.suites}}
                            <span v-if="immobile.suites >1">suites</span>
                            <span v-else>suite</span>
                          )
                        </span>
                      </span>
                    </li>
                  </template>
                </ul>

                <div v-if="isMobile" class="separator"></div>


                <div  v-if="isMobile" class="vertical-info-menu vertical-info-menu-mobile">
                  <ul>
                    <li :class="current_vertical_menu == 'gallery' ? 'active':''"  @click="runVerticalMenu('gallery')">
                      <img src="@/assets/img/lancamentos/details/detail-picture.webp" class="immobile-description-details" width="1" height="1" alt="Galeria"/>
                      Galeria
                    </li>
                    <li :class="current_vertical_menu == 'description' ? 'active':''" @click="runVerticalMenu('description')">
                      <img src="@/assets/img/details/details-building.webp" class="immobile-description-details" width="1" height="1" alt="Imóvel"/>
                      Descrição do imóvel
                    </li>
                    <li :class="current_vertical_menu == 'project' ? 'active':''"  @click="runVerticalMenu('project')">
                      <img src="@/assets/img/lancamentos/details/details-floor.webp" class="immobile-description-details" width="1" height="1" alt="Plantas"/>
                      Plantas
                    </li>
                    <li :class="current_vertical_menu == 'map' ? 'active':''"  @click="runVerticalMenu('map')">
                      <img src="@/assets/img/lancamentos/details/detail-map.webp" class="immobile-description-details" width="1" height="1" alt="Proximidades"/>
                      Mapa
                    </li>
                  </ul>
                </div>

                <div class="separator"></div>

                <PropertyDetailsSidebar
                  v-if="isMobile"
                  :immobile="immobile"
                  class="details-mobile-form"
                ></PropertyDetailsSidebar>

                <div v-if="isMobile" class="separator"></div>

                <div v-if="isMobile" class="content-block content-block-mobile-descrition">
                  <img src="@/assets/img/lancamentos/details/details-building-blue.webp" class="immobile-description-details" width="1" height="1" alt="Ficha técnica"/>
                  <div class="content">
                    <h2>{{__('Descrição')}}</h2>
                    <div v-html="immobile.descricao" class="description">
                    </div>
                  </div>
                </div>

                <div v-if="isMobile" class="separator"></div>
                

                <div class="content-block">
                  <img src="@/assets/img/lancamentos/details/details-pc-blue.webp" class="immobile-description-details" width="1" height="1" alt="Ficha técnica"/>
                  <div class="content">
                    <h2>{{__('Ficha técnica')}}</h2>
                    <div class="description">

                      <ul class="property-details">
                        <li v-for="detail in property_details">
                          {{__(detail.label)}}: <strong>{{detail.value}}</strong>
                        </li>
                      </ul>

                    </div>
                  </div>
                </div>

                <div  v-show="immobile.features && immobile.features.length > 0" class="separator"></div>

                <div  v-show="immobile.features && immobile.features.length > 0" class="content-block">
                  <img src="@/assets/img/lancamentos/details/details-home-blue.webp" class="immobile-description-details" width="1" height="1" alt="Características"/>
                  <div class="content">
                    <h2>{{__('Características')}}</h2>
                    <div class="description">

                      <ul class="property-features">
                        <li v-for="feature in immobile.features">
                          {{feature}}
                        </li>
                      </ul>

                    </div>
                  </div>
                </div>


                <div v-show="property_nearby && property_nearby.length > 0" class="separator"></div>

                <div v-show="property_nearby && property_nearby.length > 0" class="content-block">
                  <img src="@/assets/img/lancamentos/details/details-location-blue.webp" class="immobile-description-details" width="1" height="1" alt="Proximidades"/>
                  <div class="content">
                    <h2>{{__('Proximidades')}}</h2>
                    <div class="description">
                      
                      <ul class="property-nearby">
                        <li v-for="near in property_nearby">
                          {{near}}
                        </li>
                      </ul>

                    </div>
                  </div>
                </div>

                <div class="separator"></div>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </section>

    <div v-if="business == 'Vendas'" class="box-consortium-container container">
      <boxConsortiumVue :additional_emails="[immobile.lojaemail, 'performance@ops.digital']" :layout="isMobile ? 'box' : 'banner'"/>
    </div>
  
    <div v-show="similar_properties_count > 0" class="container">
      <SimilarPropertiesMain
        :property="immobile"
        v-on:properties_count="(count) => {similar_properties_count = count}"
      />
    </div>

  </div>
</template>
<script>

import "@/assets/scss/custom/lancamentos/_property-details.scss"

import Vue from "vue"

import PropertyDetailsHeader from "../components/apolar/lancamentos/property-details-header.vue";
import PropertyDetailsSidebar from "../components/apolar/lancamentos/property-details-sidebar.vue";
import SimilarPropertiesMain from "../components/apolar/similar-properties-main.vue";
import PreparedSearchs from "../components/apolar/principal/prepared-searchs.vue"
import boxConsortiumVue from '../components/apolar/principal/box-consortium.vue';
import jQuery from "jquery";


import PropertiesService from "../../services/PropertiesService"


export default {
  name: "PropertyDetails",
  components: {
    PropertyDetailsHeader,
    PropertyDetailsSidebar,
    SimilarPropertiesMain,
    PreparedSearchs,
    boxConsortiumVue
  },
  metaInfo() {
    return {
      title: this.property_title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.seo_description
        }
      ]
    };
  },
  data() {
    return {
      reference: '',
      immobile: {},
      property_types_terrains: ['Terreno','Sítio','Chácara','Área','Área Condominio Fechado'],
      form_lancamentos: {
        open: false,
        immobile_name:''
      },
      showFone: '',
      similar_properties_count: 0,
      refs_to_not_use_extra_info: [
        '206973'
      ],
      current_vertical_menu: ''
    };
  },
  computed: {

    shortDescription() {
      if (Object.keys(this.immobile).length > 0) {
        return jQuery("<div></div>").html(this.immobile.descricao).text().slice(0, 500) + "...";
      }

      return '...'
    },

    areaTypes() {
        return ['Terreno', 'Sítio', 'Chácara', 'Área', 'Área Condominio Fechado'].indexOf(this.immobile.tipo) != -1 ? true : false
    },

    business() {
      return Object.keys(this.immobile).length > 0 ? this.immobile.transacao : null
    },


    area_info() {
        return this.areaTypes ? `${Math.trunc(this.immobile.areaterreno)} m²` : `${Math.trunc(this.immobile.area_total)} m²`
    },

    property_title: function() {
      var title = ""

      if (Object.keys(this.immobile).length > 0) {
        title = `${this.immobile.tipo} ${this.immobile.finalidade} para ${(this.immobile.transacao == "Vendas" ? "Venda" : "Locação")} no ${this.immobile.bairro_list[0]} em ${this.immobile.cidade}, ${this.area_info}`
      }

      return title
    },

    property_title_h1: function() {
      var title = ""

      if (Object.keys(this.immobile).length > 0) {

        if (this.immobile.condominio) {
          title = `${this.immobile.condominio}`
        } else {
          title = `${this.immobile.tipo} ${this.immobile.finalidade} para ${(this.immobile.transacao == "Vendas" ? "Venda" : "Locação")} no ${this.immobile.bairro_list[0]}`
        }

      }

      return title
    },

    business_title: function() {
      if (Object.keys(this.immobile).length > 0) {
        if (this.immobile.condominio) {
          return `Condomínio residencial`
        }
        return `Lançamentos Apolar`
      }
      return ''
    },

    seo_description: function() {
      return `${this.property_title} você encontra aqui na Apolar! Entre em contato conosco e realize seu sonho!`
    },

    property_map_url: function() {
      return `https://www.google.com.br/maps/place/${this.immobile.EixoX} ${this.immobile.EixoY}`
    },


    property_highlights: function() {
  
      var highlights = []

      if ( Object.keys(this.immobile).length > 0 ) {

        highlights.push({
          "icon": "total_area",
          "new_icon": require("@/assets/img/details/details-rule.webp"),
          "value": `${this.area_info}`
        })

        if (this.immobile.banheiro > 0) {
          highlights.push({
            "icon": "bathrooms",
            "new_icon": require("@/assets/img/details/details-bath.webp"),
            "value": `${this.immobile.banheiro} ${ this.immobile.banheiro > 1 ? this.__("banheiros") : this.__("banheiro") }`
          })
        }

        if (this.immobile.garagem > 0) {
          highlights.push({
            "icon": "garage",
            "new_icon": require("@/assets/img/details/details-garage.webp"),
            "value": `${this.immobile.garagem} ${ this.immobile.garagem > 1 ? this.__("vagas") : this.__("vaga") }`
          })
        }

        if (this.immobile.dormitorios > 0) {
          highlights.push({
            "icon": "room",
            "new_icon": require("@/assets/img/details/details-room.webp"),
            "value": `${this.immobile.dormitorios} ${ this.immobile.dormitorios > 1 ? this.__("quartos") : this.__("quarto") }`
          })
        }

        if (this.immobile.mobiliado && this.immobile.mobiliado.toLowerCase() != "n") {
          highlights.push({
            "icon": "furnished",
            "new_icon": require("@/assets/img/details/details-room.webp"),
            "value": this.__(`mobiliado`)
          })
        }

        if (this.immobile.AceitaPets) {
          highlights.push({
            "icon": "pet",
            "new_icon": require("@/assets/img/details/details-room.webp"),
            "value": `pet`
          })
        }
      }

      return highlights
    },


    property_details: function() {

      var details = []

      if ( Object.keys(this.immobile).length > 0 ) {

        details.push({
          "label": "Cidade",
          "value": `${this.immobile.cidade}`
        })

        details.push({
          "label": "Área Terreno",
          "value": `${this.immobile.areaterreno}m²`
        })


        // let imediacoes = null
        // if ( typeof this.immobile.imediacoes != 'undefined' && this.immobile.imediacoes.length > 0 ) {
        //   imediacoes = this.immobile.imediacoes.join(', ')
        // }

        // if (imediacoes) {
        //    details.push({
        //       "label": "Imediações",
        //       "value": imediacoes
        //     })
        // }
       

        details.push({
          "label": "Área Total",
          "value": `${this.immobile.area_total}m²`
        })


        if (this.immobile.dormitorios > 0) {
          details.push({
            "label": "Dormitórios",
            "value": this.immobile.dormitorios
          })
        }

        details.push({
          "label": "Área Útil",
          "value": `${this.immobile.area_util}m²`
        })

        if ( typeof this.immobile.suites != 'undefined' &&  this.immobile.suites > 0 ) {
          details.push({
            "label": "Suítes",
            "value": this.immobile.suites
          })
        }


        if (this.immobile.garagem > 0) {
          details.push({
            "label": "Garagem",
            "value": this.immobile.garagem
          })
        }

        if (typeof this.immobile.idade != 'undefined' &&  this.immobile.idade > 0) {
          details.push({
            "label": "Ano de Construção",
            "value": `${this.immobile.idade}`
          })
        }

        details.push({
            "label": "Condomínio",
            "value": this.immobile.condominiofechado == 0 || this.immobile.condominiofechado == 'N' ? 'Não' : 'Sim'
          })

        if (typeof this.immobile.pavimentacao != 'undefined' &&  this.immobile.pavimentacao != '') {
         details.push({
            "label": "Pavimentação",
            "value": this.immobile.pavimentacao
          })
        }

        if (this.immobile.mobiliado && this.immobile.mobiliado.toLowerCase() != "n") {
          details.push({
            "label": "Mobiliado",
            "value": `mobiliado`
          })
        }


        // details.push({
        //     "label": "Permuta",
        //     "value": this.immobile.permuta == '' || this.immobile.permuta == 'N' || this.immobile.permuta == 0 ? 'Não' : 'Sim'
        //   })

        if (this.immobile.face) {
          details.push({
            "label": "Face",
            "value": this.immobile.face
          })
        }

        if (typeof this.immobile.Andar != 'undefined' && this.immobile.Andar > 0) {
          details.push({
            "label": "Andar",
            "value": this.immobile.Andar
          })
        }

        if (typeof this.immobile.Andares != 'undefined' && this.immobile.Andares > 0) {
          details.push({
            "label": "Andares",
            "value": this.immobile.Andares
          })
        }

        
      }

      return details

    },

    property_nearby: function() {

      let imediacoes = []
      if ( typeof this.immobile.imediacoes != 'undefined' && this.immobile.imediacoes.length > 0 ) {
        imediacoes = this.immobile.imediacoes.filter(i => i)
      }

      if ( imediacoes.length > 0 ) {
        return imediacoes.map(i => i.toLowerCase())
      }

      return []

    },

    locality_selected_label: function () {
      let list = this.localities_options.filter(
        (opt) => opt.value == this.locality_selected
      );
      return list.length > 0 ? list[0].label : "";
    },
    unidadesDisponiveis: function() {
      return this.immobile.NumeroUnidades == 1 ? 
      "1 unidade disponível" : 
      `${this.immobile.NumeroUnidades} unidades disponíveis`
    },
    valorApartir: function() {
      if (typeof this.immobile.valorvenda == "undefined")
        return ''
      let valor = this.number_format(this.immobile.valorvenda, 2, ',', '.')
      return `A partir de R$${valor}`
    },
    metragemRange: function() {
      if (typeof this.immobile.TamDeAte == "undefined") {
        return ''
      }
        
      if ( typeof this.immobile.TamDeAte == "number" ) {
        return `${this.immobile.TamDeAte}m²`
      }

      let [de, ate] = this.immobile.TamDeAte.split("até")
      return de.toString().trim() == ate.toString().trim() ? `${de}m²` : `${de}m² a ${ate}m²`
    },
    domitoriosRange: function() {
      if (typeof this.immobile.DormDeAte == "undefined")
        return ''
      let [de, ate] = this.immobile.DormDeAte.split("até")
      return de.toString().trim() == ate.toString().trim() ? `${de} dormitório${(de > 1 ? 's' : '')}` : `${de} a ${ate} dormitórios`
    },
    banherioSuite: function() {
      let banheiros = '0 banheiro'
      if (this.immobile.banheiro > 1)
        banheiros = this.immobile.banheiro > 1 ? `${this.immobile.banheiro} banheiros` : `1 banheiro`

      let suites = ''
      if (this.immobile.suites > 1)
        suites = this.immobile.suites > 1 ? `${this.immobile.suites} suítes` : `1 suíte`

      if (banheiros != '' && suites != '')
        return `${banheiros} e ${suites}`

      if (banheiros == '' && suites != '')
        return suites

      if (banheiros != '' && suites == '')
        return banheiros  
    },
    vagas: function() {
      if ( this.immobile.garagem == 0 ) {
        return `0 vaga`
      }
      return this.immobile.garagem > 1 ? `${this.immobile.garagem} vagas` : `1 vaga`
    },
    breadcrumbs: function() {

      if ( Object.keys(this.immobile).length == 0 ) {
        return []
      }

      let business_label = ['Vendas', 'Lancamento'].indexOf(this.immobile.transacao) != -1 ? "Comprar" : "Alugar"
      let business_slug = ['Vendas', 'Lancamento'].indexOf(this.immobile.transacao) != -1 ? "venda" : "alugar"

      let cidade_label = this.immobile.cidade
      let cidade_slug = this.slugify(cidade_label)

      let bairro_label = this.immobile.bairro
      let bairro_slug = this.slugify(bairro_label)

      let endereco_label = this.immobile.endereco
      let endereco_slug = this.slugify(endereco_label)

      return [
          {"title":"Apolar Imóveis", "url": this.baseUrl},
          {"title": business_label, "url": `${this.baseUrl}/${business_slug}`},
          {"title": cidade_label, "url": `${this.baseUrl}/${business_slug}/${cidade_slug}`},
          {"title": bairro_label, "url": `${this.baseUrl}/${business_slug}/${cidade_slug}/${bairro_slug}`},
          //{"title": endereco_label, "url": `${this.baseUrl}/${business_slug}/${cidade_slug}/${bairro_slug}/${endereco_slug}`},
      ]

    },

    apolar_event_data: function() {
      if (Object.keys(this.immobile).length > 0) {
        return {
          "loja": this.immobile.loja,
          "referencia": this.immobile.referencia,
          "transacao": this.immobile.transacao,
          "tipo_imovel": this.immobile.tipo_search,
          "cidade": this.immobile.cidade_search,
          "bairro": this.immobile.bairro_search,
          "area": this.immobile.area_total,
        }
      }
      return null
    },

    no_extra_info: function() {
      try {
        var referencia = this.immobile.referencia.toString()
      } catch (error) {
        var referencia = this.immobile.referencia
      }
      return this.refs_to_not_use_extra_info.indexOf(referencia) != -1
    }
  },
  methods: {
    openLocalityOptions: function () {
      if (
        document
          .querySelector(".locality_selected")
          .classList.contains("opened")
      ) {
        document.querySelector(".locality_selected").classList.remove("opened");
        document
          .querySelector("ul.filter-localities-options")
          .classList.remove("opened");
      } else {
        document.querySelector(".locality_selected").classList.add("opened");
        document
          .querySelector("ul.filter-localities-options")
          .classList.add("opened");
      }
    },
    changeLocality: function (locality) {
      this.locality_selected = locality;
      document.querySelector(".locality_selected").classList.remove("opened");
      document
        .querySelector("ul.filter-localities-options")
        .classList.remove("opened");
    },
    backToSeach: function () {
      this.$router.push({
        name: "lancamentos-property-search"
      });
    },
    loadProperty: function() {

      
      //transaction
      let reference_parts = this.$router.history.current.params.slug.split('-')
      const reference = reference_parts[reference_parts.length -1]

      let query_string = ""
      if ( typeof this.$route.query != 'undefined' &&  Object.keys(this.$route.query).length > 0 ) {
        query_string = `?${new URLSearchParams(this.$route.query).toString()}`
      }

      Vue.set(this, 'reference', reference)
      PropertiesService.getByReference(this.reference + 'V', 'main', query_string)
      .then(
        response => {
          var data = response.data

          let url_to_check = this.get_property_url(data)

          if ( url_to_check.indexOf(this.$route['path']) == -1 ) {
            window.location.href = url_to_check
            return false
          }

          data.descricao = data.descricao.replace(/\n\n/g, "<br/><br/>")
          data.descricao = data.descricao.replace(/\n/g, "<br/>")
          Vue.set(this, 'immobile', response.data)
          this.register_apolar_event('pageview', 'imovel', this.apolar_event_data)
        }
      )
    },
    shareThis: function() {
      if (navigator.share) {
        navigator.share({
          title: `${this.immobile.condominio} - Apolar Imóveis`,
          url: window.location.href
        })
      }
    },

    viewOnMap: function() {
      window.open(this.property_map_url, '_blank').focus();
    },

    goToDetails: function() {
      jQuery([document.documentElement, document.body]).animate({
          scrollTop: jQuery(".property-description").offset().top -50
      }, 2000);
    },
    initjQuery() {

      jQuery("#app").addClass('main-property-details-lancamentos')
      jQuery(window).on('scroll', () => {

          if (this.isMobile) {
            return false
          }

          let window_scroll = window.scrollY
          let thumb_slide_height = document.querySelector(".thumb-slide").scrollHeight + 70
          let header_scroll_pos = (document.querySelector('.property-header-default').offsetTop + document.querySelector('.property-header-default').scrollHeight) - thumb_slide_height
          let property_content_scroll_pos = document.querySelector('.property-details-main-content').offsetTop + document.querySelector('.property-details-main-content').scrollHeight
          let side_from_pos = document.querySelector('.side-form').scrollHeight + window_scroll
          
          if (window_scroll >= header_scroll_pos) {
            if ( document.querySelector('.side-form').scrollHeight < document.querySelector('.property-details-main-content').scrollHeight) {
              if (side_from_pos < property_content_scroll_pos) {
                let _top = window_scroll - header_scroll_pos - thumb_slide_height
                jQuery('.side-form').css({'position': 'absolute', 'top':_top + 70, 'bottom': 'unset' })
              } else {
                jQuery('.side-form').css({'position': 'absolute', 'bottom':20, 'top': 'unset' })
              }
            } 
          } else {
            jQuery('.side-form').css({'position': 'absolute', 'top': '-185px' , 'bottom': 'unset'})
          }


        })
    },
    openFormLancamentos(immobile_name) {
      this.form_lancamentos.immobile_name = immobile_name
      this.form_lancamentos.open = true
    },
    slugify(text) {
      return text
        .toString()                           // Cast to string (optional)
        .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
        .toLowerCase()                  // Convert the string to lowercase letters
        .trim()                                  // Remove whitespace from both sides of a string (optional)
        .replace(/\s+/g, '-')            // Replace spaces with -
        .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
        .replace(/\-\-+/g, '-');        // Replace multiple - with single -
    },
    openWhatsapp() {
        this.register_apolar_event('click', 'whatsapp', this.apolar_event_data)
        var text = this.__("Olá preciso de informações sobre esse imóvel: ") +  window.location.href;
        window.open(
            'https://api.whatsapp.com/send?phone=+55'+ this.immobile.lojacelular.replace(/\D/g,'') +'&text=' + encodeURIComponent(text),
            '_blank'
        );
    },

    openFone() {
        this.register_apolar_event('click', 'telefone', this.apolar_event_data)
        this.showFone = this.immobile.lojatelefone
        if ( this.isMobile ) {
            window.location.href = 'tel:' + this.immobile.lojatelefone.replace(/\D/g,'');
        }
    },

    scrollTo: function(elem, diff=0) {
       let pos =  jQuery(elem).offset().top
       pos += diff
       jQuery([document.documentElement, document.body]).animate({
          scrollTop: pos
      }, 1000);
    },

    runVerticalMenu: function(menu, delay=1000) {
      switch (menu) {
        case "description":

          if (this.isMobile) {
            this.scrollTo(".content-block-mobile-descrition", -100)
          } else {
            this.scrollTo('.property-details', -100)
          }
          
          break;

        case "gallery":
           jQuery([document.documentElement, document.body]).animate({
              scrollTop: 0
          }, delay, ()=>{
            this.$refs['property-header'].changeTab('photo')
            this.$refs['property-header'].showPropetySlidePopupAction()
          });
          break;

        case "map":
           jQuery([document.documentElement, document.body]).animate({
              scrollTop: 0
          }, delay, ()=>{
            this.$refs['property-header'].changeTab('map')
          });
          break;

        case "video":
           jQuery([document.documentElement, document.body]).animate({
              scrollTop: 0
          }, delay, ()=>{
            this.$refs['property-header'].changeTab('video')
          });
          break;
      
        default:
          break;
      }

      this.current_vertical_menu = menu
    }
  },
  created() {
    this.initjQuery()
    this.loadProperty()
  }
};
</script>
