<template>
    <header class="header-search" :class="getHeaderClass">

        <router-link slot="brand" class="navbar-brand" to="/">
            <img width="93" height="59" :src="apolar_logo" alt="Apolar Imóveis">
        </router-link>

        <div class="search-autocomplete">
            <VSelectInfinite
                ref="vselectsearchby"
                :options="filters_options.open_search"
                :placeholder="__('Bairro, cidade, endereço ou referência')"
                v-model="filters.open_search"
                v-on:input="handleOpenSearch"
                :min_search="true"
            />
        </div>

        <div v-if="lang != 'es'" class="header-menu-nav">
            <a target="_blank" href="https://anuncie.apolar.com.br/" class="header-buttons" >Anuncie</a>
            <a class="header-buttons alert-button" href="javascript:void(0)"> <span class="site-icons icon-bell"></span> Criar alerta</a>
            <a target="_blank" href="/areacliente" class="header-buttons customer-area-button">Área do Cliente</a>
            <base-nav class="navbar-main" type="">
                <ul class="navbar-nav align-items-lg-center ml-lg-auto">
                    <li 
                        v-for="menu,idx in menu_disposition" 
                        v-bind:key="`menu-${idx}`"
                        :class="typeof getMenu(menu).submenu !='undefined' ? 'has-submenu': ''"
                    >
                        <a  target="_blank" :class="getMenu(menu).class" :href="getMenu(menu).link">
                            {{getMenu(menu).label}}
                        </a>

                        <ul v-if="getMenu(menu).submenu && getMenu(menu).submenu.length > 0" class="sub-menu">
                            <li 
                                v-for="sub,subidx in getMenu(menu).submenu"
                                v-bind:key="`submenu-${subidx}`"
                            >
                                <a  target="_blank" :href="sub.link">{{sub.label}}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </base-nav>
        </div>
    </header>
</template>
<script>
import Vue from 'vue'
import "@/assets/scss/custom/principal/components/_header_search.scss"
import vSelect from 'vue-select'
import BaseNav from "@/components/BaseNav";
import PropertiesService from "../../../../services/PropertiesService";
Vue.component('v-select', vSelect)
import VSelectInfinite from "../VSelectInfinite.vue"
import 'vue-select/dist/vue-select.css';


export default {
  components: {
    BaseNav,
    VSelectInfinite
  },
  computed: {
      getHeaderClass() {
          return `header_${this.$route.name}`
      },

      apolar_logo() {
        if ( this.lang == 'es') {
            return require('@/assets/img/principal-logo-header-es.webp')
        }
        return require('@/assets/img/logo-apolar-imoveis-high.webp')
      }
  },
  data() {
    return {
        menu_disposition: [],
        menu_desktop_disposition:[
            'Comprar','Alugar','Produtos Apolar','Indique', 
            'Anunciar Imóveis', 'Seja um Franqueado', 'Área Cliente'
        ],
        menu_mobile_disposition:[
            'Seja um Franqueado', 'Área Cliente', 'Anunciar Imóveis',
            'Indique', 'Alugar', 'Comprar', 'Produtos Apolar'
        ],
        menus: [
            {
                "link": "/venda",
                "label": "Comprar",
                "class": "",
                "submenu": [
                    {
                        "link": "/venda?residencial",
                        "label": "Residencial"
                    },
                    {
                        "link": "/lancamentos",
                        "label": "Lançamentos"
                    },
                    {
                        "link": "/triplea",
                        "label": "Triple A"
                    },
                    {
                        "link": "/venda?comercial",
                        "label": "Comercial"
                    },
                    {
                        "link": "/feirao-apolar",
                        "label": "Feirão Apolar"
                    }
                ]
            },
            {
                "link": "/alugar",
                "label": "Alugar",
                "class": "",
                "submenu": [
                    {
                        "link": "/alugar?mensal",
                        "label": "Residencial"
                    },
                    {
                        "link": "/alugar?comercial",
                        "label": "Comercial"
                    },
                    {
                        "link": "https://shortstay.apolar.com.br/",
                        "label": "ShortStay"
                    },
                    {
                        "link": "/temporada",
                        "label": "Temporada"
                    },
                    {
                        "link": "https://opsaluguei.com.br/",
                        "label": "Ops! Aluguei"
                    }
                ]
            },
            {
                "link": "#",
                "label": "Produtos Apolar",
                "class": "",
                "submenu": [
                    {
                        "link": "https://procura.apolar.com.br/",
                        "label": "Apolar Procura"
                    },
                    {
                        "link": "https://consorcioapolar.com.br/",
                        "label": "Consórcio"
                    },
                    {
                        "link": "/feirao-apolar",
                        "label": "Feirão Apolar"
                    },
                    {
                        "link": "/invista",
                        "label": "Para Investidores"
                    },
                    {
                        "link": "/b4b",
                        "label": "Built for Business"
                    },
                    {
                        "link": "/franquias/",
                        "label": "Nossas Lojas"
                    }
                ]
            },
            {
                "link": "https://opsganhei.com.br/",
                "label": "Indique",
                "class": "indique",
                "submenu": []
            },
            {
                "link": "/anuncie",
                "label": "Anunciar Imóveis",
                "class": "anunciar-imoveis",
                "submenu": []
            },
            {
                "link": "https://apolarfranqueadora.com.br/",
                "label": "Seja um Franqueado",
                "class": "special-button seja-franqueado"
            },
            {
                "link": "/areacliente/",
                "label": "Área Cliente",
                "class": "special-button area-cliente"
            }
        ],
        filters: {
            open_search: '',
        },
        filters_options: {
            open_search: []
        }
    };
  },
  methods: {

    setMenuLayout() {
        this.menu_disposition = this.menu_desktop_disposition
        if (this.isMobile) {
             this.menu_disposition = this.menu_mobile_disposition
        }
    },

    getMenu(menu_name) {
        let find_menu = this.menus.filter(menu => menu.label == menu_name)
        if ( find_menu.length > 0 ) {
            return find_menu[0]
        }
        return null
    },

    handleOpenSearch() {
        
        let open_search = this.filters.open_search
        let check_is_reference = parseInt(open_search)

        console.log("open_search: ", open_search)

        //check se é busca por numero de referencia
        if ( !isNaN(check_is_reference) ) {
            this.filters.reference = check_is_reference
        } else {

            this.filters.reference = null
            let open_search_parts = open_search.split(',')
            
            switch (open_search_parts.length) {
                case 1:
                    Vue.set(this.filters, 'city', open_search_parts[0].trim())
                    Vue.set(this.filters, 'district', [])
                    Vue.set(this.filters, 'address', null)
                    Vue.set(this.filters, 'address_number', null)
                    break;

                case 2:
                    Vue.set(this.filters, 'city', open_search_parts[1].trim())
                    Vue.set(this.filters, 'district', [open_search_parts[0].trim()])
                    Vue.set(this.filters, 'address', null)
                    Vue.set(this.filters, 'address_number', null)
                    break;

                case 3:
                    Vue.set(this.filters, 'city', open_search_parts[2].trim())
                    //Vue.set(this.filters, 'district', [open_search_parts[1].trim()])
                    Vue.set(this.filters, 'address', open_search_parts[0].trim())
                    Vue.set(this.filters, 'address_number', null)
                    break;

                case 4:
                    Vue.set(this.filters, 'city', open_search_parts[3].trim())
                    //Vue.set(this.filters, 'district', [open_search_parts[1].trim()])
                    Vue.set(this.filters, 'address_number', open_search_parts[1].trim())
                    Vue.set(this.filters, 'address', open_search_parts[0].trim())
                    break;

                default:
                    break;
            }

        }

        this.search()
        
    },

    search() {

        //busca preparada para enviar para site atual em wordpress
        let filters = {}
        filters.filters = {}
        
        if (this.filters.reference) {
            filters.filters.referencia = this.filters.reference
        } else {

            if (this.filters.business) {
                filters.filters.finalidade = this.filters.business
            }

            if (this.filters.city) {
                filters.filters.cidade = this.filters.city ? this.filters.city.trim() : null
            }

            if (this.filters.district) {
                filters.filters.bairro = this.filters.district
            }

            if ( this.filters.address ) {
                filters.filters.endereco = this.filters.address ? this.filters.address.trim() : null
            }
            
        }

        let filter_type = typeof this.filters.property_type != 'undefined' && this.filters.property_type && this.filters.property_type.length > 0 ? this.slugify(this.filters.property_type[0]) : null
        if ( typeof this.filters.property_type_combo != 'undefined' &&  this.filters.property_type_combo.length > 0 && !filter_type ) {
            let type_combo = this.filters.property_type_combo[0].split("|")
            filter_type = this.slugify(type_combo[0])
        }

        let route_params = {
            transaction: this.filters.business == "Vendas" ? 'venda' : 'alugar',
            type: filter_type,
            city: this.filters.city ? this.slugify(this.filters.city) :  null,
            district: this.filters.district && this.filters.district.length > 0 ? this.slugify(this.filters.district[0]) : null,
            address: this.filters.address ? this.slugify(this.filters.address) :  null
        }

        let purpose = typeof this.filters.business_subfilter != 'undefined' ? this.slugify(this.filters.business_subfilter) : null

        let query = {}
        if (purpose) {
            query[purpose]=null;
        }

        this.filters['header_search'] = true
        //Vue.set(this, '$advanced_filters', {})
        Vue.set(this, '$advanced_filters', this.filters)

        this.$emit("filterApplied", true)        
        this.$router.push({
                name: "principal-property-search",
                query: query,
                params: route_params
        });

    }

  },
  mounted() {
     this.setMenuLayout()

     PropertiesService.getFilters('main')
    .then(
        response => {
            //this.filters_options.cities = response.data.cities
            let cities = []
            let districts = []
            let address = []

            let curitiba_obj = response.data.cities.filter(city_obj => city_obj.city == 'Curitiba')
            let other_cities = response.data.cities.filter(city_obj => city_obj.city != 'Curitiba')
            this.filters_options.cities = curitiba_obj.concat(other_cities)

            this.filters_options.cities.forEach((city)=>{
                cities.push(city.city)
                city.districts.forEach((district)=>{
                    districts.push(`${district}, ${city.city}`)
                })
            })

            response.data.enderecos.forEach((addrs)=>{
                let _address_without_number = `${addrs.address}, ${addrs.district}, ${addrs.city}`
                let _address_with_number = `${addrs.address}, ${addrs.number}, ${addrs.district}, ${addrs.city}`

                if ( address.indexOf(_address_without_number) == -1 ) {
                    address.push(_address_without_number)
                }

                if ( address.indexOf(_address_with_number) == -1 ) {
                    address.push(_address_with_number)
                }
            })

            this.filters_options.open_search = cities.concat(districts, address, response.data.referencias)
        }
    )
  }
};
</script>
<style>
</style>
