<template>
    <div class="property-seal-component" :class="`seal-type-${type}`">
        <img v-if="type != 'situacao' && typeof property.Selo != 'undefined' && property.Selo.toLowerCase() == '5 estrelas'" alt="Selo Ouro" src="@/assets/img/feirao-apolar-selo-ouro.webp" width="1" height="1"/>
        <img v-if="type != 'situacao' && typeof property.Selo != 'undefined' && property.Selo.toLowerCase() == '4 estrelas'" alt="Selo Prata" src="@/assets/img/feirao-apolar-selo-prata.webp" width="1" height="1"/>
        <img v-if="type != 'situacao' && typeof property.Selo != 'undefined' && property.Selo.toLowerCase() == '3 estrelas'" alt="Selo Bronze" src="@/assets/img/feirao-apolar-selo-bronze.webp" width="1" height="1"/>
        <img v-if="type != 'situacao' && typeof property.Selo != 'undefined' && property.Selo.toLowerCase() == 'super desconto'" alt="Super Desconto" class="superdesconto" src="@/assets/img/feirao-apolar-selo-superdesconto.webp" width="1" height="1"/>

        <img v-if="type == 'situacao' && property.situacao.toLowerCase() == 'locado'" alt="Imóvel Locado" src="@/assets/img/rented-stamp.webp"/>
        <img v-if="type == 'situacao' && property.situacao.toLowerCase() == 'vendido'" alt="Imóvel Vendido" src="@/assets/img/stamp-sold.webp"/>
    </div>
</template>
<script>

export default {
  components: {
  },
  props: {
    type: {
      type: String,
      default() {
        return "feirao"//feirao/situacao
      }
    },
    property: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  computed: {
  },
  data() {
    return {
    
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>
<style>
</style>
